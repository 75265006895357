<template>
    <section class="iq-section iq-coffers">
        <!-- coffers header start -->
        <header class="iq-coffers__header">
            <span>我的猫着平台总收益</span>
            <strong>
                <small>￥</small>
                <em>{{ info.totalIncomeAmout }}</em>
            </strong>
            <div class="iq-coffers__header-progress" @click="goToTeam">我的团队<em>{{info.teamMemberCount}} /
                {{info.mediumLevelCount}}</em>人
            </div>
            <div class="iq-coffers__header-users">
                <router-link to="/visitor">我的访问</router-link>
            </div>
        </header>
        <!-- coffers header end -->
        <!-- coffers nums start -->
        <nav class="iq-coffers__nums">
            <div class="iq-coffers__nums-flex">
                <div class="iq-coffers__nums-item">
                    <label>可提现</label>
                    <strong>{{ info.toWithdrawalAmout }}</strong>
                </div>
                <div class="iq-coffers__nums-item">
                    <router-link to="/ytxian">
                        <label>已提现</label>
                        <strong>{{ info.withdrawalFinishedAmout }}</strong>
                    </router-link>
                </div>
                <div class="iq-coffers__nums-item">
                    <router-link to="/txzhong">
                        <label>提现中 </label>
                        <strong>{{ info.withdrawalProcessingAmout }}</strong>
                    </router-link>
                </div>
                <div class="iq-coffers__nums-item">
                    <label>直卖收益</label>
                    <strong>{{ info.selfBuyIncomeAmout }}</strong>
                </div>
                <div class="iq-coffers__nums-item">
                    <label>
                        <router-link to="/tixian">
                            升级后可提现
                        </router-link>
                        <van-popover v-model="showPopover" theme="dark" trigger="click">
                          <p class="iq-coffers__desc">您当前为小团长，下下级的收益暂存，您发展20位团队成员，成为大团长后即可提现。</p>
                          <template #reference>
                            <van-icon name="question-o" v-if="$store.getters.userinfo.userLevel <= 1" style="margin-left: 5px"/>
                          </template>
                      </van-popover>
                    </label>
                    <strong>{{ info.upLevelToWithdrawalIncomeAmout }}</strong>
                </div>
                <div class="iq-coffers__nums-item">
                    <router-link to="/shwtongguo">
                        <label>提现未通过</label>
                        <strong>{{ info.withdrawalAuditFailedAmout }}</strong>
                    </router-link>
                </div>
            </div>
        </nav>
        <!-- coffers nums end -->
        <!-- coffers detail start -->
        <section class="iq-coffers__detail">
            <h3 class="iq-coffers__detail-title">
                <span>订单详情</span>
            </h3>
            <!-- boxes -->
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <div
                            class="iq-coffers__detail-boxes"
                            v-for="(item, $index) in list"
                            :key="$index"
                    >
                        <h4>订单号: {{ item.orderNo }}</h4>
                        <p>{{ item.productName }}</p>
                        <!-- info -->
                        <div class="iq-coffers__detail-info">
                            <span>姓名: {{ item.userName }}</span>
                            <span>电话: {{ item.userPhone }}</span>
                            <span class="red">佣金: {{ item.totalCommission }}</span>
                            <span>创建时间: {{ item.createTime }}</span>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </section>
        <!-- coffers detail end -->
        <!-- coffers footer start -->
        <footer class="iq-coffers__footer">
            <div class="iq-coffers__footer-item active">我的金库</div>
            <div class="iq-coffers__footer-item">
                <router-link to="/sqtixian">
                    申请提现
                </router-link>
            </div>
            <div class="iq-coffers__footer-item">
                <router-link to="/Drxinxi">
                    团长信息
                </router-link>
            </div>
        </footer>
        <!-- coffers footer end -->
    </section>
</template>

<script>
  import {getSelfBuyOrderList, userAccountStat} from "@/api/user";

  export default {
    name: "Coffers",
    components: {},
    data () {
      return {
        info: {},
        pageNum: 1,
        pages: 1,
        list: [],
        refreshing: false,
        loading: false,
        finished: false,
        showPopover: false
      };
    },
    methods: {
      loadBaseInfo () {
        userAccountStat().then((data) => {
          if (data.success == true) {
            this.info = data.data;
          }
        });
      },
      goToTeam () {
        this.$router.push('/team')
      },
      loadSelfOrder () {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return;
        }
        this.loading = true;
        getSelfBuyOrderList({
          pageNum: this.pageNum,
        })
          .then((data) => {
            if (data.success == true) {
              this.pageNum = data.data.current + 1;
              this.pages = data.data.pages;
              this.list.push(...data.data.records);
              if (this.pageNum > this.pages) {
                this.finished = true;
              }
            } else {
              this.finished = true;
            }
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadSelfOrder();
      },
      search () {
        this.refreshing = true;
        this.onRefresh();
      },
    },
    created () {
      this.loadBaseInfo();
      this.loadSelfOrder();
    },
  };
</script>

<style lang="scss" scoped>
    .iq-coffers {
        height: 100%;
        font-size: 14px;
        line-height: 1;
        box-sizing: border-box;
        padding-bottom: 100px;

        a {
            color: #333;
        }

        &__ {
            &header {
                position: relative;
                height: 170px;
                padding: 0 20px;
                background: rgb(220, 87, 82);
                color: #fff;

                span {
                    display: block;
                    padding: 20px 0 10px;
                    line-height: 28px;
                }

                strong {
                    font-size: 30px;

                    small {
                        padding-right: 5px;
                        font-size: 14px;
                    }

                    em {
                        font-style: normal;
                    }
                }

                &- {

                    &progress,
                    &users {
                        position: absolute;
                        right: 0;
                        height: 28px;
                        line-height: 28px;
                        padding: 0 15px;
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                        background: #fff;
                        color: #999;
                    }

                    &users {
                        top: 60px;
                    }

                    &progress {
                        top: 20px;

                        em {
                            font-weight: normal;
                            font-style: normal;
                            color: #666;
                            padding: 0 5px;
                        }
                    }
                }
            }

            &desc {
              width: 120px;
              padding: 10px;
              font-size: 12px;
            }

            &nums {
                position: relative;
                z-index: 11;
                width: auto;
                height: 120px;
                margin: -70px 10px 0;
                padding: 10px 15px 0px;
                background: #fff;
                box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                box-sizing: border-box;
                overflow: hidden;

                &- {
                    &flex {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    &item {
                        flex: 0 0 33.33%;
                        text-align: left;
                        margin-bottom: 10px;

                        &:not(:nth-child(3n + 1)) {
                            label {
                                text-decoration: underline;
                            }
                        }

                        label,
                        strong {
                            display: block;
                        }

                        label {
                            position: relative;
                            font-size: 12px;
                            margin: 5px 0;

                            i {
                                position: absolute;
                                top: 50%;
                                width: 14px;
                                height: 14px;
                                transform: translateY(-50%);
                                /*background: url("../assets/icon-qa.png") center no-repeat;*/
                                background-size: cover;
                            }
                        }

                        strong {
                            padding-top: 5px;
                            margin-bottom: 5px;
                            font-size: 14px;
                        }
                    }
                }
            }

            &detail {
                padding: 10px;

                &- {
                    &title {
                        line-height: 18px;
                        padding-left: 23px;
                        font-weight: normal;
                        background: url("../assets/icon-order.png") left center no-repeat;
                        background-size: 18px;
                        margin-bottom: 10px;

                        span {
                            font-size: 14px;
                        }
                    }

                    &boxes {
                        background: #fff;
                        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
                        margin-bottom: 15px;
                        padding: 15px 15px 5px;
                        box-sizing: border-box;
                        font-size: 12px;

                        h4 {
                            color: #333;
                            margin-bottom: 10px;
                            font-size: 12px;
                            font-weight: normal;
                        }

                        p {
                            color: #666;
                            margin-bottom: 20px;
                        }
                    }

                    &info {
                        span {
                            display: block;
                            margin-bottom: 10px;

                            &.red {
                                color: rgb(129, 66, 64);
                            }
                        }
                    }
                }
            }

            &footer {
                position: fixed;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                width: 100%;
                height: 44px;
                background: #fff;

                &- {

                    &item {
                        height: 100%;
                        flex: 1;
                        padding-top: 30px;
                        font-size: 12px;
                        background-repeat: no-repeat;
                        background-position: center 5px;
                        background-size: 20px;
                        box-sizing: border-box;
                        text-align: center;

                        &.active {
                            color: rgb(220, 87, 82);
                        }

                        &:nth-child(1) {
                            background-image: url('../assets/icon-center.png');

                            &.active {
                                background-image: url('../assets/icon-center-a.png');
                            }
                        }

                        &:nth-child(2) {
                            background-image: url('../assets/icon-card.png');

                            &.active {
                                background-image: url('../assets/icon-card-a.png');
                            }
                        }

                        &:nth-child(3) {
                            background-image: url('../assets/icon-me.png');

                            &.active {
                                background-image: url('../assets/icon-me-a.png');
                            }
                        }
                    }
                }
            }
        }
    }
</style>
